import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import EveTool from '../components/content/EveTool.vue'
import Introduction from '../components/content/Introduction.vue'
import Industry from '../components/content/tool/Industry.vue'
import Company from '../components/content/Company.vue'
import Market from '../components/content/tool/Market.vue'
import EveLp from '../components/content/tool/EveLp.vue'
import EveKb from '../components/content/tool/EveKb.vue'
import EveHole from '../components/content/tool/EveHole.vue'
import EveTask from '../components/content/EveTask.vue'
import Ship from '../components/content/ShipConfiguration.vue'
import Navigation from '../components/content/tool/Navigation.vue'
import Appeal from '../components/content/tool/Appeal.vue'
import Chinese from '../components/content/tool/Chinese.vue'
import Scanning from '../components/content/tool/Scanning.vue'
import EveMarket from '../components/content/tool/EveMarket.vue'
import EveIndustry from '../components/content/tool/EveIndustry.vue'
import DeadSpace from '../components/content/tool/DeadSpace.vue'
import EveWiki from '../components/content/tool/EveWiki.vue'
import Fuli from '../components/content/tool/Fuli.vue'
import Zhidu from '../components/content/tool/Zhidu.vue'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes:[
    { path: '/' , redirect: '/home' },
    { path: '/login', component: Login,name:'login'},
    { path: '/home', component: Home, name:'home',
    //路由重定向
    redirect: '/introduction',
    children: [{ path: '/welcome', component: Welcome, name:'welcome'},
    { path: '/evetool', component: EveTool,name:'evetool' },
    { path: '/introduction', component: Introduction,name:'introduction' },
    { path: '/industry', component: Industry,name:'industry' },
    { path: '/company',component: Company,name:'company' },
    { path: '/market',component: Market,name:'market'},
    { path: '/evelp',component: EveLp,name:'evelp'},
    { path: '/evekb',component: EveKb, name:'evekb'},
    { path: '/evehole',component: EveHole,name:'evehole'},
    { path: '/evetask', component: EveTask,name:'evetask'},
    { path: '/ship', component: Ship,name:'ship'},
    { path:'/navigation', component:Navigation,name:'navigation'},
    { path:'/appeal', component:Appeal,name:'appeal'},
    { path:'/chinese', component:Chinese,name:'chinese'},
    { path:'/scanning',component:Scanning, name:'scanning'},
    { path:'/evemarket',component:EveMarket, name:'evemarket'},
    { path:'/eveindustry',component:EveIndustry, name:'eveindustry'},
    { path:'/deadspace',component:DeadSpace ,name:'deadspace'},
    { path:'/evewiki',component:EveWiki , name:'evewiki'},
    { path:'/fuli',component:Fuli,name:'fuli'},
    { path:'/zhidu',component:Zhidu,name:'zhidu'}
    ]
    }
    ]
})
const VueRouterPush = Router.prototype.push

Router.prototype.push = function push(to) {

  return VueRouterPush.call(this, to).catch(err => err)

}

export default router
