<template>
  <div>
     你好舰船配置
     <img src="@/assets/img/bg.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

</style>