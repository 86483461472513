<template>
    <div>
        <div class="header w">
            <!-- logo -->
            <div class="logo">
                <h1>
                    <a href="#" title="D.OK" @click="home" id="logo">D.OK</a>
                </h1>
            </div>
            <!-- 欢迎标语 -->
            <div class="hy fr">欢迎来到D.OK公司!</div>
            <!-- nav开始 -->
            <div class="nav">
                <div class="w">
                    <!-- 军团相关 -->
                    <div class="gsjs fl" @click="eve_switch_gsjs()" v-bind:class="{bgc: clickli === 0}">
                        公司介绍
                    </div>
                    <!-- 军团其他内容 -->
                    <div class="gsqt fr">
                        <ul>
                            <li><a href="#" @click="eve_switch_gsyz()" v-bind:class="{bgc: clickli === 1}">公司运转可视化</a>
                            </li>
                            <li><a href="#" @click="eve_switch_gsjz()" v-bind:class="{bgc: clickli === 2}">公司建制</a></li>
                            <li><a href="#" @click="eve_switch_rwgl()" v-bind:class="{bgc: clickli === 3}">游戏攻略视频</a>
                            </li>
                            <li><a href="#" @click="eve_switch_gyzz()" v-bind:class="{bgc: clickli === 4}">工业制造</a></li>
                            <li><a href="#" @click="eve_switch_gj()" v-bind:class="{bgc: clickli === 5}">eve工具</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="zhuti w">
            <router-view></router-view>

        </div>
        <!-- 判断密码是否正确跳转公司建制 -->

        <el-dialog el-dialog title="请输入密码" :visible.sync="mmDialogVisible" width="20%" ref="mmRef">
            <el-input placeholder="请输入内容" v-model="input" clearable show-password>
            </el-input>
            <el-row class="btns">
                <el-button type="primary" round @click="loginjz">跳转</el-button>
            </el-row>
        </el-dialog>
         <!-- 判断密码是否正确跳转工业制造 -->
        <el-dialog el-dialog title="请输入密码" :visible.sync="mnDialogVisible" width="20%" ref="mmRef">
            <el-input placeholder="请输入内容" v-model="input1" clearable show-password>
            </el-input>
            <el-row class="btns">
                <el-button type="primary" round @click="logingy">跳转</el-button>
            </el-row>
        </el-dialog>
        <!-- nav结束 -->
    </div>
    <!-- https://amber-town-8bd.notion.site/35782a4339c0477db08409cecd6a05b7 -->

</template>
<script>


export default {
    data() {
        return {
            isbgc: 'true',
            clickli: '0',
            mmDialogVisible: false,
            mnDialogVisible:false,
            // 绑定的密码对话框输入内容
            input: '',
            input1:'',
        }
    },
    created() {
        this.eve_switch_gsjs();

    },
    methods: {
        home() {
            this.$router.push('/home');
        },
        eve_switch_gsjs() {
            this.clickli = 0;
            this.$router.push('/introduction')
        },
        eve_switch_gsyz() {
            this.clickli = 1;
            this.$router.push('/company');

        },
        eve_switch_gsjz() {
            this.clickli = 2;
            this.mmDialogVisible = true;
        },
        eve_switch_rwgl() {
            this.clickli = 3;
            this.$router.push('/evetask');

        },
        eve_switch_gyzz() {
            // this.$router.push('/industry');
            this.clickli = 4;
            this.mnDialogVisible = true;
  
        },
        eve_switch_gj() {
            this.clickli = 5;
            this.$router.push('/evetool');
            // event.currentTarget.parentElement .srcElement.isbgc = false

        },
        loginjz() {
            if (this.input == 'hs1234') {
               var jz
                this.mmDialogVisible = false
                if(jz){
                    mm.close();
                }
                jz  = window.open("https://amber-town-8bd.notion.site/c7928af77cae49a9829d177062d05236");
            }
        },
        logingy(){
            if (this.input1 == 'hs1234') {
                var gy 
                this.mnDialogVisible = false
                if(gy) {
                    gy.close();
                }
                gy=window.open("https://amber-town-8bd.notion.site/35782a4339c0477db08409cecd6a05b7");
                
            }
        }
    },

}
</script>
<style lang="less" scoped>
@import '../assets/css/common.css';
@import '../assets/css/base.css';

.zhuti {
    position: relative;
    top: 49px;
    height: 1080px;

    .icp {
        position: absolute;
        left: 50%;
        bottom: 50px;
        font-size: 30px;
        transform: translate(-50%, -50%)
    }
}

.btns {
    display: flex;
    justify-content: flex-end;
}

.bgc {
    background-color: #b1191a;
    color: #fff;
}
</style>