import { render, staticRenderFns } from "./DeadSpace.vue?vue&type=template&id=502d2b15&scoped=true&"
import script from "./DeadSpace.vue?vue&type=script&lang=js&"
export * from "./DeadSpace.vue?vue&type=script&lang=js&"
import style0 from "./DeadSpace.vue?vue&type=style&index=0&id=502d2b15&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502d2b15",
  null
  
)

export default component.exports