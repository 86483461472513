<template>
    <div class="w">
        <div class="title">本页面并非本人所写,集成他人页面</div>
        <iframe src="https://zkillboard.com/" style="height:1000px;width: 100%;" scrolling="auto"></iframe>
    </div>

</template>

<script>
// import { ids } from 'webpack';

export default {
    data() {
        return {
        }
    },
    methods: {
       
    }
}
</script>

<style lang="less" scoped>
.title {
    display: block;
    height: 100px;
    text-align: center;
    color: aqua;
    font-size: 50px;
    background: url(@/assets/img/head_bg.png) no-repeat;
    background-size: 1600px 100px;
}
</style>