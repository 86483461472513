export default{
    didian :[
            {
              "category": "region",
              "id": 10000001,
              "name": "德里克"
            },
            {
              "category": "region",
              "id": 10000002,
              "name": "伏尔戈"
            },
            {
              "category": "region",
              "id": 10000003,
              "name": "静寂谷"
            },
            {
              "category": "region",
              "id": 10000004,
              "name": "UUA-F4"
            },
            {
              "category": "region",
              "id": 10000005,
              "name": "底特里德"
            },
            {
              "category": "region",
              "id": 10000006,
              "name": "邪恶湾流"
            },
            {
              "category": "region",
              "id": 10000007,
              "name": "地窖"
            },
            {
              "category": "region",
              "id": 10000008,
              "name": "灼热之径"
            },
            {
              "category": "region",
              "id": 10000009,
              "name": "因斯姆尔"
            },
            {
              "category": "region",
              "id": 10000010,
              "name": "特布特"
            },
            {
              "category": "region",
              "id": 10000011,
              "name": "大荒野"
            },
            {
              "category": "region",
              "id": 10000012,
              "name": "柯尔斯"
            },
            {
              "category": "region",
              "id": 10000013,
              "name": "糟粕之域"
            },
            {
              "category": "region",
              "id": 10000014,
              "name": "卡彻"
            },
            {
              "category": "region",
              "id": 10000015,
              "name": "维纳尔"
            },
            {
              "category": "region",
              "id": 10000016,
              "name": "长征"
            },
            {
              "category": "region",
              "id": 10000017,
              "name": "J7HZ-F"
            },
            {
              "category": "region",
              "id": 10000018,
              "name": "螺旋之域"
            },
            {
              "category": "region",
              "id": 10000019,
              "name": "A821-A"
            },
            {
              "category": "region",
              "id": 10000020,
              "name": "塔什蒙贡"
            },
            {
              "category": "region",
              "id": 10000021,
              "name": "域外走廊"
            },
            {
              "category": "region",
              "id": 10000022,
              "name": "混浊"
            },
            {
              "category": "region",
              "id": 10000023,
              "name": "黑渊"
            },
            {
              "category": "region",
              "id": 10000025,
              "name": "伊梅瑟亚"
            },
            {
              "category": "region",
              "id": 10000027,
              "name": "琉蓝之穹"
            },
            {
              "category": "region",
              "id": 10000028,
              "name": "摩登赫斯"
            },
            {
              "category": "region",
              "id": 10000029,
              "name": "对舞之域"
            },
            {
              "category": "region",
              "id": 10000030,
              "name": "西玛特尔"
            },
            {
              "category": "region",
              "id": 10000031,
              "name": "绝径"
            },
            {
              "category": "region",
              "id": 10000032,
              "name": "金纳泽"
            },
            {
              "category": "region",
              "id": 10000033,
              "name": "赛塔德洱"
            },
            {
              "category": "region",
              "id": 10000034,
              "name": "卡勒瓦拉阔地"
            },
            {
              "category": "region",
              "id": 10000035,
              "name": "德克廉"
            },
            {
              "category": "region",
              "id": 10000036,
              "name": "破碎"
            },
            {
              "category": "region",
              "id": 10000037,
              "name": "埃维希尔"
            },
            {
              "category": "region",
              "id": 10000038,
              "name": "幽暗之域"
            },
            {
              "category": "region",
              "id": 10000039,
              "name": "埃索特亚"
            },
            {
              "category": "region",
              "id": 10000040,
              "name": "欧莎"
            },
            {
              "category": "region",
              "id": 10000041,
              "name": "辛迪加"
            },
            {
              "category": "region",
              "id": 10000042,
              "name": "美特伯里斯"
            },
            {
              "category": "region",
              "id": 10000043,
              "name": "多美"
            },
            {
              "category": "region",
              "id": 10000044,
              "name": "孤独之域"
            },
            {
              "category": "region",
              "id": 10000045,
              "name": "特纳"
            },
            {
              "category": "region",
              "id": 10000046,
              "name": "斐德"
            },
            {
              "category": "region",
              "id": 10000047,
              "name": "普罗维登斯"
            },
            {
              "category": "region",
              "id": 10000048,
              "name": "宁静之域"
            },
            {
              "category": "region",
              "id": 10000049,
              "name": "卡尼迪"
            },
            {
              "category": "region",
              "id": 10000050,
              "name": "逑瑞斯"
            },
            {
              "category": "region",
              "id": 10000051,
              "name": "云环"
            },
            {
              "category": "region",
              "id": 10000052,
              "name": "卡多尔"
            },
            {
              "category": "region",
              "id": 10000053,
              "name": "钴蓝边域"
            },
            {
              "category": "region",
              "id": 10000054,
              "name": "艾里迪亚"
            },
            {
              "category": "region",
              "id": 10000055,
              "name": "血脉"
            },
            {
              "category": "region",
              "id": 10000056,
              "name": "非塔波利斯"
            },
            {
              "category": "region",
              "id": 10000057,
              "name": "外环"
            },
            {
              "category": "region",
              "id": 10000058,
              "name": "源泉之域"
            },
            {
              "category": "region",
              "id": 10000059,
              "name": "摄魂之域"
            },
            {
              "category": "region",
              "id": 10000060,
              "name": "绝地之域"
            },
            {
              "category": "region",
              "id": 10000061,
              "name": "特里菲斯"
            },
            {
              "category": "region",
              "id": 10000062,
              "name": "欧米斯特"
            },
            {
              "category": "region",
              "id": 10000063,
              "name": "贝斯"
            },
            {
              "category": "region",
              "id": 10000064,
              "name": "精华之域"
            },
            {
              "category": "region",
              "id": 10000065,
              "name": "柯埃佐"
            },
            {
              "category": "region",
              "id": 10000066,
              "name": "佩利根弗"
            },
            {
              "category": "region",
              "id": 10000067,
              "name": "吉勒西斯"
            },
            {
              "category": "region",
              "id": 10000068,
              "name": "维格温铎"
            },
            {
              "category": "region",
              "id": 10000069,
              "name": "暗涌之域"
            },
            {
              "category": "region",
              "id": 11000006,
              "name": "B-R00006"
            },
            {
              "category": "region",
              "id": 11000007,
              "name": "B-R00007"
            },
            {
              "category": "region",
              "id": 11000008,
              "name": "B-R00008"
            },
            {
              "category": "region",
              "id": 11000009,
              "name": "C-R00009"
            },
            {
              "category": "region",
              "id": 11000010,
              "name": "C-R00010"
            },
            {
              "category": "region",
              "id": 11000011,
              "name": "C-R00011"
            },
            {
              "category": "region",
              "id": 11000012,
              "name": "C-R00012"
            },
            {
              "category": "region",
              "id": 11000013,
              "name": "C-R00013"
            },
            {
              "category": "region",
              "id": 11000014,
              "name": "C-R00014"
            },
            {
              "category": "region",
              "id": 11000015,
              "name": "C-R00015"
            },
            {
              "category": "region",
              "id": 11000016,
              "name": "D-R00016"
            },
            {
              "category": "region",
              "id": 11000017,
              "name": "D-R00017"
            },
            {
              "category": "region",
              "id": 11000018,
              "name": "D-R00018"
            },
            {
              "category": "region",
              "id": 11000019,
              "name": "D-R00019"
            },
            {
              "category": "region",
              "id": 11000020,
              "name": "D-R00020"
            },
            {
              "category": "region",
              "id": 11000021,
              "name": "D-R00021"
            },
            {
              "category": "region",
              "id": 11000022,
              "name": "D-R00022"
            },
            {
              "category": "region",
              "id": 11000023,
              "name": "D-R00023"
            },
            {
              "category": "region",
              "id": 11000024,
              "name": "E-R00024"
            },
            {
              "category": "region",
              "id": 11000025,
              "name": "E-R00025"
            },
            {
              "category": "region",
              "id": 11000026,
              "name": "E-R00026"
            },
            {
              "category": "region",
              "id": 11000027,
              "name": "E-R00027"
            },
            {
              "category": "region",
              "id": 11000028,
              "name": "E-R00028"
            },
            {
              "category": "region",
              "id": 11000029,
              "name": "E-R00029"
            },
            {
              "category": "region",
              "id": 11000030,
              "name": "F-R00030"
            },
            {
              "category": "region",
              "id": 11000031,
              "name": "G-R00031"
            },
            {
              "category": "region",
              "id": 11000032,
              "name": "H-R00032"
            },
            {
              "category": "region",
              "id": 11000033,
              "name": "K-R00033"
            },
            {
              "category": "region",
              "id": 12000001,
              "name": "ADR01"
            },
            {
              "category": "region",
              "id": 12000002,
              "name": "ADR02"
            },
            {
              "category": "region",
              "id": 12000003,
              "name": "ADR03"
            },
            {
              "category": "region",
              "id": 12000004,
              "name": "ADR04"
            },
            {
              "category": "region",
              "id": 12000005,
              "name": "ADR05"
            },
            {
              "category": "region",
              "id": 11000001,
              "name": "A-R00001"
            },
            {
              "category": "region",
              "id": 14000001,
              "name": "VR-01"
            },
            {
              "category": "region",
              "id": 14000002,
              "name": "VR-02"
            },
            {
              "category": "region",
              "id": 14000003,
              "name": "VR-03"
            },
            {
              "category": "region",
              "id": 14000004,
              "name": "VR-04"
            },
            {
              "category": "region",
              "id": 14000005,
              "name": "VR-05"
            },
            {
              "category": "region",
              "id": 11000002,
              "name": "A-R00002"
            },
            {
              "category": "region",
              "id": 11000003,
              "name": "A-R00003"
            },
            {
              "category": "region",
              "id": 11000004,
              "name": "B-R00004"
            },
            {
              "category": "region",
              "id": 11000005,
              "name": "B-R00005"
            },
            {
              "category": "region",
              "id": 10000070,
              "name": "波赫文"
            }
          ],
      // 任务
    task:[
      {
        "index":"1-1-1",
        "name":"燃烧代理人天蛇",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403480163&page=2"
      },
      {
        "index":"1-1-2",
        "name":"燃烧代理人萨沙",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403479812&page=1"
      },
      {
        "index":"1-1-3",
        "name":"燃烧代理人血袭",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403486823&page=4"
      },
      {
        "index":"1-1-4",
        "name":"燃烧代理人天使",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403479932&page=3"
        
      },
      {
        "index":"1-2-1",
        "name":"混乱团队a族",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403480762&page=5"
      },
      {
        "index":"1-2-2",
        "name":"混乱团队c族",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403483739&page=6"
      },
      {
        "index":"1-2-3",
        "name":"混乱团队g族",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403482243&page=7"

      },
      {
        "index":"1-2-4",
        "name":"混乱团队m族",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403485420&page=8"
        
      },
      {
        "index":"1-3-1",
        "name":"混乱基地g族塔罗斯",
        "url":"//player.bilibili.com/player.html?aid=548387465&bvid=BV1jq4y1N7eS&cid=420880244&page=1"
      },
      {
        "index":"1-4-1",
        "name":"混乱团队代理人通刷涅加尔配置",
        "url":"//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=411588268&page=9"
      },
      {
        "index":"1-4-2",
        "name":"混乱基地g族塔罗斯恩尤配置",
        "url":"//player.bilibili.com/player.html?aid=548387465&bvid=BV1jq4y1N7eS&cid=420880244&page=1"
      },
      {
        "index":"2-1-1",
        "name":"斩龙行动-战列舰",
        "url":"//player.bilibili.com/player.html?aid=67169876&bvid=BV1iJ411N732&cid=116467271&page=1"
      },
      {
        "index":"2-1-2",
        "name":"灭龙行动-战列舰",
        "url":"//player.bilibili.com/player.html?aid=373084108&bvid=BV1JZ4y1g7Hf&cid=264454245&page=3"
        
      },
      {
        "index":"2-1-3",
        "name":"自由间谍1-战列舰",
        "url":"//player.bilibili.com/player.html?aid=373084108&bvid=BV1JZ4y1g7Hf&cid=264454245&page=2"
      },
      {
        "index":"2-1-4",
        "name":"净化的火焰-战列舰",
        "url":"//player.bilibili.com/player.html?aid=67169876&bvid=BV1iJ411N732&cid=116467271&page=1"
      },
      {
        "index":"2-1-5",
        "name":"天使的愤怒-战列舰",
        "url":"//player.bilibili.com/player.html?aid=67169876&bvid=BV1iJ411N732&cid=116467271&page=5"
      },
      {
        "index":"2-2-1",
        "name":"解放矿工-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=1"
      },
      {
        "index":"2-2-2",
        "name":"净化的火焰-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=2"
      },
      {
        "index":"2-2-3",
        "name":"聚集力量-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=3"
      },
      {
        "index":"2-2-4",
        "name":"开拓号-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=4"
      },
      {
        "index":"2-2-5",
        "name":"灭龙行动-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=5"
      },
      {
        "index":"2-2-6",
        "name":"清理门户-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=6"
      },

      {
        "index":"2-2-7",
        "name":"荣誉-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=7"
      },
      {
        "index":"2-2-8",
        "name":"萨沙取物-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=8"
      },
      {
        "index":"2-2-9",
        "name":"天使的愤怒-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=9"
      },
      {
        "index":"2-2-10",
        "name":"袭击护航队a族-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=10"
      },
      {
        "index":"2-2-11",
        "name":"袭击护航队c族-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=11"
      },
      {
        "index":"2-2-12",
        "name":"越狱(2)-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=12"
      },
      {
        "index":"2-2-13",
        "name":"斩龙行动-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=13"
      },
      {
        "index":"2-2-14",
        "name":"重重一蛰(1)-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=14"
      },
      {
        "index":"2-2-15",
        "name":"重重一蛰(2)-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=15"
      },
      {
        "index":"2-2-16",
        "name":"繁殖设施-掠夺舰",
        "url":"//player.bilibili.com/player.html?aid=294091384&bvid=BV1jF411Y7b3&cid=437387195&page=16"
      },      
    ]
}