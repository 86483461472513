<template>
    <div class="w">
        <div class="jieshao">
             &emsp;&emsp;DOK军团规章制度
            <br>
             &emsp;&emsp;军团七项基本原则
            <br>
            &emsp;&emsp;1.团结、友爱、互助、互利
            <br>
            &emsp;&emsp;2.禁止水贴吧
            <br>
            &emsp;&emsp;3.禁止游戏内或QQ吵架(有事请找狗管理)
            <br>
            &emsp;&emsp;4.上游戏 上YY
            <br>
            &emsp;&emsp;5.提交seat
            <br>
            &emsp;&emsp;6.在常规生产船只技能完毕后跟随军团/联盟建制队路线
            <br>
            &emsp;&emsp;7.有问题勤于沟通
            <br>
            &emsp;&emsp;
            军团规章细则请参考联盟规定
            <br>
            &emsp;&emsp;<a href="https://docs.qq.com/doc/DVnZFbllieWlFeVRh" target="_blank">https://docs.qq.com/doc/DVnZFbllieWlFeVRh</a>




        </div>
    </div>

</template>

<script>
// import { ids } from 'webpack';

export default {
    data() {
        return {
        }
    },
    methods: {
       
    }
}
</script>

<style lang="less" scoped>
.w {
    height: 100%;
    background: url("~@/assets/img/background.jpg");
    color: aqua;
    font-size: 35px;
    a{
        color: aqua;
    }}
</style>