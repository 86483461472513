import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
// 导入全局样式表
import './assets/css/global.css'
//引入字体样式库
import './assets/font/iconfont.css'
//echartsjs文件
import *as echarts from 'echarts'
import 'echarts'
import ECharts from 'vue-echarts'
//引入水滴图
import 'echarts-liquidfill'
//引入vuex的store
import store from './store'
//图片懒加载
import VueLazyLoad from 'vue-lazyload'
// import vuex from 'vuex'
Vue.prototype.$echarts = echarts
Vue.component('e-charts', ECharts)

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(VueLazyLoad,{
  error:require("@/assets/img/error.png"),
  loading:require("@/assets/img/loading.png"),
})
axios.defaults.baseURL = 'https://esi.evetech.net/latest'
new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
