<template>
    <div class="introduction">
        <!-- 标题 -->
       <div class="title">Faceless. 公司欢迎你们来到本页面。</div>
          <div class="rshu fr">游戏人数为{{this.rshu}}人</div>
        <el-carousel height="208px" class="zmd" style="width:1060px" type="card">
            <el-carousel-item v-for="item in imgBox" :key="item.id">
            <img v-lazy="item.idView" :alt="item.name">
            </el-carousel-item>
        </el-carousel>
        <div class="jieshao">
             &emsp;&emsp;军团简介：D.OK无面者军团成立于2020年6月，隶属于帝国系D.C联盟，是一个PVP和PVE兼顾的军团，军团福利优厚，PVP意志强烈，内部气氛融洽，
             包容度高，热情欢迎萌新，战士，以及喜欢PVE的朋友加入到我们的团队中来共同学习，共同成长。
            <br>
             &emsp;&emsp;语音以原名开黑了，现在改名的kook。qq群号是1106199534，加群后会人联系你，或者直接询问会有人回答。
            <br>
            <a href="https://jq.qq.com/?_wv=1027&k=H4uFtsec" target="_blank"><el-button type="primary" class="btn" >加入qq群</el-button></a>
            <el-button  type="danger" class="btn" @click="fuli">福利</el-button>
            <el-button  type="warning" class="btn" @click="zhidu">制度</el-button>
            <a href="https://kook.top/R6I4pr" target="_blank"><el-button type="success" class="btn">加入开黑了</el-button></a>
            
        </div>
        <div class="icp"><a href="http://beian.miit.gov.cn/">
                皖ICP备2022013012号-1</a></div>
        <div style="width:300px;margin:0 auto; padding:20px 0;" class="icp1">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34011102003300"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/img/beian.png"
                    style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">皖公网安备
                    34011102003300号</p>
            </a>
        </div>
        
    </div>
 
</template>
<script>


export default {
    data(){
        return{
          imgBox:[
            {id:0, idView: require('@/assets/img/tengu.png') ,name:'金鹏级'},
            {id:1, idView: require('@/assets/img/loki.png'), name:'洛基级'},
            {id:2, idView: require('@/assets/img/proteus.png'),name:'海神级'},
            {id:3, idView: require('@/assets/img/legion.png'), name:'圣卒级'},
          ],
          rshu:'',
        }
    },
  mounted() {
   this.reshu();
  },
  methods:{
    async reshu(){
        const {data : res} = await this.$http.get("/status/")
        this.rshu = res.players       
    },
    fuli(){
        this.$router.push('/fuli')
    },
    zhidu(){
        this.$router.push('/zhidu')
    }
  },
    
}
</script>
<style lang = "less" scoped>
.introduction {
    height: 100%;
    background: url("~@/assets/img/background.jpg");
    
}
.title {
    display: block;
    height: 100px;
    text-align: center;
    color: aqua;
    font-size: 50px;
}
.zmd{
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}
.jieshao{
    display: block;
    height: 100px;
    color: aqua;
    font-size: 30px;
    .zi{
        position: absolute;
        top: 750px;
        right: 0;
    }
    a{
        padding: 10px;
        
    }
    .btn{
        width: 300px;
        height: 200px;
        position: relative;
    left: 10%;
    }
    
}
.button{
    position: relative;
    top:400px;
}
.rshu {
    height: 50px;
    width: 300px;
    color: aqua;
    font-size: 25px;
    text-align: center;
}
.icp {
    position: absolute;
    left: 50%;
    bottom: 140px;
    font-size: 30px;
    transform: translate(-50%, -50%)
}
.icp1 {
    position: absolute;
    left: 50%;
    bottom: 160px;
    font-size: 15px;
    transform: translate(-50%, -50%)
}
</style>