<template>
    <div class="login">
        <div class="login_box">
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" label-width="100px"
                class="login_form">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="loginForm.username" prefix-icon="iconfont icon-yonghu"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="loginForm.password" prefix-icon="iconfont icon-suoding" :show-password="true">
                    </el-input>
                </el-form-item>
                <el-row class="btns">
                    <el-button type="primary" round @click="login">登录</el-button>
                    <el-button type="info" round @click="resetLoginForm">重置</el-button>
                </el-row>
            </el-form>
        </div>
        <div class="icp"><a href="http://beian.miit.gov.cn/">
                皖ICP备2022013012号-1</a></div>
        <div style="width:300px;margin:0 auto; padding:20px 0;" class="icp1">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34011102003300"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="@/assets/img/beian.png"
                    style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">皖公网安备
                    34011102003300号</p>
            </a>
        </div>

    </div>
</template>
<script>

export default {
    data() {
        return {
            //登录用户数据
            loginForm: {
                username: 'admin',
                password: '123456'
            },
            code: "",
            loginFormRules: {
                //    验证用户名是否合法
                username: [
                    { required: true, message: '请输入登录名称', trigger: 'blur' },
                    { min: 3, max: 10, message: '长度在3到10个字符', trigger: 'blur' }
                ],
                // 验证密码是否合法
                password: [
                    { required: true, message: '请输入登录密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '长度在6到15个字符', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        resetLoginForm() {
            this.$refs.loginFormRef.resetFields()
        },
        login() {
            this.$refs.loginFormRef.validate(async valid => {
                if (!valid) return false
                this.$router.push('/home')
            })
        },
    },
}
</script>
<style lang="less" scoped>
.login {
    height: 100%;
    background: url("~@/assets/img/background.jpg");
}

.login_box {
    width: 350px;
    height: 200px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login_form {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 5px;
    box-sizing: border-box;
}

.btns {
    display: flex;
    justify-content: flex-end;
}

.icp {
    position: absolute;
    left: 50%;
    bottom: 140px;
    font-size: 30px;
    transform: translate(-50%, -50%)
}
.icp1 {
    position: absolute;
    left: 50%;
    bottom: 160px;
    font-size: 15px;
    transform: translate(-50%, -50%)
}
</style>