<template>
    <div class="w">
        <div class="jieshao">
             &emsp;&emsp;福利
            <br>
             &emsp;&emsp;1.萌新入团满10天可领取小米一条
            <br>
            &emsp;&emsp;2.萌新入团满30天且活跃在线可领取四族T1战列舰(任选)教官可领取T2重突一条
            <br>
            &emsp;&emsp;3.萌新入团三月内完成联盟出勤5次奖励1.5E isk 新手教官获得1.5E isk(每额外增加一个PAP 教官及新手同时获得1000W ISK)
            <br>
            &emsp;&emsp;4.萌新参与收割或反收割完成第一个KM可领取5000Wisk 首次单独完成击杀可领取1.5E isk
后续可凭KM领取 1000W奖励，萌新参与联盟队并获取击杀KM 每次可领取2E isk以上教官及萌新同时获取
            <br>
            &emsp;&emsp;5.萌新三个月内升级至欧米茄账户，教官可获得2E isk
            <br>
            &emsp;&emsp;6.萌新5个月内完成联盟建制重突、T2后勤任意一种船型，奖励对应建制船一条且教官获得2Eisk
            <br>
            &emsp;&emsp;7.萌新三月后完成军团考核奖励全装小白一条及5Eisk教官可获得中白或T3战略巡洋舰二选一及5Eisk
            <br>
            &emsp;&emsp;8.萌新入团后三个月内教官及萌新每月可享受 100000m免费快递 快递由石头负责(军团付费）
            <br>
            &emsp;&emsp;
以上福利萌新入团且绑定教官有效(萌新入团三个月内有效 其中福利“5”延续至5个月)
            <br>
          

        </div>
    </div>

</template>

<script>
// import { ids } from 'webpack';

export default {
    data() {
        return {
        }
    },
    methods: {
       
    }
}
</script>

<style lang="less" scoped>
.w {
    height: 100%;
    background: url("~@/assets/img/background.jpg");
    color: aqua;
    font-size: 35px;}
</style>