<template>
    <div>
        <el-row>
            <!-- 公司运转头部 -->
            <el-col :span="24">
                <div class="hd">
                    <h1>Faceless.公司运转</h1>
                </div>
            </el-col>
        </el-row>
        <!-- 主体部分 -->
        <el-row class="m ">
            <el-col :span="6">
                <!-- 主体左部分 -->
                <div class="ml">
                    <div class="panel pie">
                        <e-charts :option="option_d" class="echart" />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel">
                        <e-charts :option="option_c" class="echart" />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel">
                        <e-charts :option="option_a" class="echart" />
                        <div class="panel-footer"></div>
                    </div>

                </div>
            </el-col>
            <!-- 主体中间部分 -->
            <el-col :span="12">
                <div class="mm">
                    <div class="map1"></div>
                    <div class="map2"></div>
                    <div class="map3"></div>
                    <e-charts :option="option_e" class="echarts" />
                </div>
            </el-col>
            <el-col :span="6">
                <!-- 主体右部分 -->
                <div class="mr">
                    <div class="panel">
                        <e-charts :option="option_b" class="echart" />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel">
                        <e-charts :option="option" class="echart" />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel">
                        <e-charts :option="option_f" class="echart" />
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            //饼图
            option: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    textStyle: {
                        color: 'red',
                        fontWeight: 'bold'
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 2, name: '16-18岁' },
                            { value: 10, name: '18-25岁' },
                            { value: 2, name: '25-30岁' },
                            { value: 2, name: '30-40岁' },
                        ]
                    }
                ]
            },
            //仪表盘
            option_a: {

                tooltip: {
                    formatter: '{a} <br/>{b} : {c}%'
                },
                series: [
                    {
                        colorBy: 'data',
                        name: 'Pressure',
                        type: 'gauge',
                        detail: {
                            formatter: '{value}',
                            color: "rgba(183, 26, 26, 1)",
                            fontStyle: "oblique",
                            fontWeight: "normal",
                            fontFamily: "sans-serif",
                            backgroundColor: null,
                            borderColor: null,
                        },
                        data: [
                            {

                                title: {
                                    color: 'blue',
                                },
                                value: '30',
                                name: '活跃人口',
                            }
                        ],
                        axisLine: {
                            lineStyle: {
                                color: [[0.1, 'blue'],
                                [0.2, 'green'],
                                [0.3, 'red'],
                                [0.4, 'pink'],
                                [0.5, 'yellow'],
                                [0.6, 'purple'],
                                [0.7, 'orange'],
                                [0.8, 'violet'],
                                [0.9, 'white'],
                                [1, 'grey']]
                            }
                        }
                    }
                ],


            },
            //柱形图
            option_b: {
                grid: {
                    left: '10%',
                    left: '22%',
                    bottom: '10%',
                    // containLabel: true
                },
                xAxis: {
                    //不显示x轴信息
                    show: false
                },
                yAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        data: ["黑影特勤舰", "战略巡洋舰", "旗舰", "掠夺舰", "突击护卫舰"],
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        //把刻度标签的文字变成白色
                        axisLabel: {
                            color: "#fff",
                        },
                    },
                    {
                        type: 'category',
                        inverse: true,
                        data: [10, 20, 10, 15, 25],
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        //把刻度标签的文字变成白色
                        axisLabel: {
                            color: "#fff",
                        },
                    },
                ],
                series: [
                    {
                        name: '条',
                        type: 'bar',
                        data: [33.3, 66.6, 33.3, 50, 83.3],
                        itemStyle: {
                            barBorderRadius: 5,
                            //此刻color可以修改颜色
                            color: function (params) {
                                // 定义一个颜色集合
                                let colorList = [
                                    '#52A8FF',
                                    '#00B389',
                                    '#FFA940',
                                    '#FF5A57',
                                    '#29EFC4',
                                ];
                                // 对每个bar显示一种颜色
                                return colorList[params.dataIndex];
                            },
                        },// 柱子之间的距离
                        barCategoryGap: 50,
                        //柱子的宽度
                        barWidth: 10,
                        //显示柱子内的文字
                        label: {
                            normal: {
                                show: true,
                                // 图形内显示
                                position: "inside",
                                // 文字的显示格式
                                formatter: "{c}%"
                            }
                        },
                        yAxisIndex: 0,
                    },
                    {
                        name: '框',
                        type: 'bar',
                        // 柱子之间的距离
                        barCategoryGap: 50,
                        //柱子的宽度
                        barWidth: 15,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: "none",
                            borderColor: "#00c1de",
                            borderWidth: 3,
                            barBorderRadius: 15
                        },
                        data: [100, 100, 100, 100, 100],
                    }
                ]
            },
            // 水滴图
            option_c: {
                title: {
                    text: '公司威胁度',
                    left: 'center',
                    textStyle: {
                        color: '#446bf5'
                    }
                },
                series: [
                    {
                        type: 'liquidFill', //水位图
                        radius: '80%', //显示比例
                        center: ['50%', '50%'], //中心点
                        amplitude: 20, //水波振幅
                        data: [0.65, .65, .65], // data个数代表波浪数
                        color: [
                            {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#446bf5',
                                    },
                                    {
                                        offset: 1,
                                        color: 'red',
                                    },
                                ],
                                globalCoord: false,
                            },
                        ], //波浪颜色
                        backgroundStyle: {
                            borderWidth: 6, //外边框
                            borderColor: '#446bf5', //边框颜色
                            color: 'RGBA(51, 66, 127, 0.7)', //边框内部填充部分颜色
                        },
                        label: {
                            //标签设置
                            position: ['50%', '45%'],
                            formatter: '47%', //显示文本,
                            textStyle: {
                                fontSize: '52px', //文本字号,
                                color: '#fff',
                            },
                        },
                        outline: {
                            // show: false
                            borderDistance: 0,
                            itemStyle: {
                                borderWidth: 2,
                                borderColor: '#112165',
                            },
                        },
                    },
                ],
            },
            //折线图
            option_d: {
                // 通过这个color修改两条线的颜色
                color: ["#00f2f1", "#ed3f35"],
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    // 如果series 对象有name 值，则 legend可以不用写data
                    // 修改图例组件 文字颜色
                    textStyle: {
                        color: "#4c9bfd"
                    },
                    // 这个10% 必须加引号
                    right: "10%"
                },
                grid: {
                    top: "20%",
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    show: true, // 显示边框
                    borderColor: "#012f4a", // 边框颜色
                    containLabel: true // 包含刻度文字在内
                },

                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [
                        "1月",
                        "2月",
                        "3月",
                        "4月",
                        "5月",
                        "6月",
                        "7月",
                        "8月",
                        "9月",
                        "10月",
                        "11月",
                        "12月"
                    ],
                    axisTick: {
                        show: false // 去除刻度线
                    },
                    axisLabel: {
                        color: "#4c9bfd" // 文本颜色
                    },
                    axisLine: {
                        show: false // 去除轴线
                    }
                },
                yAxis: {
                    type: "value",
                    axisTick: {
                        show: false // 去除刻度线
                    },
                    axisLabel: {
                        color: "#4c9bfd" // 文本颜色
                    },
                    axisLine: {
                        show: false // 去除轴线
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#012f4a" // 分割线颜色
                        }
                    }
                },
                series: [
                    {
                        name: "公司总人口",
                        type: "line",
                        // true 可以让我们的折线显示带有弧度
                        smooth: true,
                        data: [50, 60, 65, 70, 78, 85, 100, 105,700]
                    }
                ]
            },
            //雷达图
            option_f: {

                // backgroundColor: '#0D2753',
                tooltip: {
                    //雷达图的tooltip不会超出div，也可以设置position属性，position定位的tooltip 不会随着鼠标移动而位置变化，不友好
                    confine: true,
                    enterable: true, //鼠标是否可以移动到tooltip区域内
                },
                radar: {
                    name: {
                        textStyle: {
                            color: '#05D5FF',
                            fontSize: 14,
                        },
                    },
                    shape: 'polygon',
                    center: ['50%', '50%'],
                    radius: '80%',
                    startAngle: 120,
                    scale: true,
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(5, 213, 255, .8)',
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            width: 1,
                            color: 'rgba(5, 213, 255, .8)', // 设置网格的颜色
                        },
                    },
                    indicator: [
                        {
                            name: '战斗舰船存量',
                            max: 100,
                        },
                        {
                            name: '活跃角色数量',
                            max: 100,
                        },
                        {
                            name: '技能合格率',
                            max: 100,
                        },
                        {
                            name: '工业制造流程数量',
                            max: 100,
                        },
                        {
                            name: '异常矿带清理速率',
                            max: 100,
                        },
                        {
                            name: '行星产物产出率',
                            max: 100,
                        },
                    ],
                    splitArea: {
                        show: false,
                    },
                },
                grid: {
                    position: 'center',
                },
                polar: {
                    center: ['50%', '50%'], // 默认全局居中
                    radius: '0%',

                },
                angleAxis: {
                    min: 0,
                    interval: 5,
                    clockwise: false,
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                },
                radiusAxis: {
                    min: 0,
                    interval: 20,
                    splitLine: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: '公司雷达图',
                        type: 'radar',
                        symbol: 'circle', // 拐点的样式，还可以取值'rect','angle'等
                        symbolSize: 10, // 拐点的大小
                        itemStyle: {
                            normal: {
                                color: '#05D5FF',
                            },
                        },
                        areaStyle: {
                            normal: {
                                color: '#05D5FF',
                                opacity: 0.5,
                            },
                        },
                        lineStyle: {
                            width: 2,
                            color: '#05D5FF',
                        },
                        label: {
                            normal: {
                                show: true,
                                formatter: (params) => {
                                    return params.value;
                                },
                                color: '#fff',
                            },
                        },
                        data: [
                            {
                                value: [40, 50, 80, 75, 80, 40],
                            },
                        ],
                    },
                ],
            },
            //属性图
            option_e: {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                    backgroundColor: '#dfdfdf',
                    textStyle: {
                        color: 'black'
                    }
                },
                series: [{
                    type: "tree",
                    symbolSize: 130,
                    symbol: 'roundRect',

                    edgeShape: 'polyline',
                    edgeForkPosition: '50%',
                    initialTreeDepth: 2,

                    orient: 'vertical',

                    itemStyle: {
                        color: 'black',
                        borderColor: 'black',
                    },

                    expandAndCollapse: true,
                    animationDuration: 550,
                    animationDurationUpdate: 750,

                    lineStyle: {
                        color: '#7b7b7b',
                        width: 5
                    },

                    label: {
                        show: true,
                        position: 'inside',
                        width: 40,
                        textStyle: {
                            fontSize: 15,
                            color: '#fff',
                        },
                    },

                    leaves: {
                        label: {

                            // 相对的百分比
                            position: ['20%', '30%'],
                            color: 'black'
                        },
                        itemStyle: {
                            color: '#dfdfdf',
                            borderColor: '#dfdfdf',
                        }
                    },

                    data: [{
                        name: "Faceless.",
                        url: '',
                        // 根节点样式设置
                        itemStyle: {
                            color: '#a53626',
                            borderColor: '#a53626',
                        },
                        children: [
                            {
                                name: "CEO 随风",
                                children: [{
                                    name: "总监\n\n天兵,\n黑旗, "
                                }, {
                                    name: "招新官\n钟离尘风, \n黑漆漆, \n悲叹之星,"
                                }, {
                                    name: "补损官\n\n鹿鹿"
                                }, {
                                    name: "矿船教官\n\nker"
                                }, {
                                    name: "月矿/垃圾处理\n\n七七"
                                }, {
                                    name: "冰矿回收\n\n空城"
                                },

                                ]
                            },]
                    }],

                }]
            }
        }
    },


    methods: {

    }
}
</script>

<style lang = "less" scoped>
// 公司运转头部
.hd {
    height: 80px;
    background: url(@/assets/img/head_bg.png) no-repeat;
    background-size: 1600px 100px;

    h1 {
        line-height: 100px;
        color: #fff;
        text-align: center;
        font-size: 50px;
    }
}

//主体部分
.m {
    height: 100%;
    box-sizing: border-box;
    padding: 10px 10px 0;
    background: url(@/assets/img/yzbgc.png) no-repeat;

    // 主体左部分
    .ml {
        .echart {
            height: 300px
        }
    }

    // 主体中间部分
    .mm {
        position: relative;
        height: 900px;

        .map1 {
            height: 518px;
            width: 518px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url(https://images.evetech.net/corporations/98645589/logo?tenant=tranquility&size=256);
            background-size: 100% 100%;
            opacity: 0.9;
        }

        .map2 {
            position: absolute;
            width: 643px;
            height: 643px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url(@/assets/img/lbx.png);
            background-size: 100% 100%;
            animation: rotate1 15s linear infinite;
            opacity: 0.6;
        }

        .map3 {
            position: absolute;
            width: 566px;
            height: 566px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url(@/assets/img/jt.png);
            background-size: 100% 100%;
            animation: rotate2 10s linear infinite;
            opacity: 0.6;
        }

        .echart {
            height: 300px
        }

        @keyframes rotate1 {
            form {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            to {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }

        @keyframes rotate2 {
            form {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            to {
                transform: translate(-50%, -50%) rotate(-360deg);
            }
        }
    }

    //主体右部分
    .mr {

        .echart {
            height: 300px
        }
    }
}
</style>