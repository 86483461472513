<template>
    <div>
        <el-container class="task">
            <!-- 左边侧栏 -->
            <el-aside width="350px">
                <el-col :span="12">
                    <el-menu class="el-menu-vertical-demo" ref="taskMenuRef" @select="chooseIndex"
                        background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
                        <el-submenu index="1">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span>燃烧任务(涅加尔)</span>
                            </template>
                             <el-submenu index="1-1">
                                <template slot="title">混乱代理人</template>
                                <el-menu-item index="1-1-1">燃烧代理人天蛇</el-menu-item>
                                <el-menu-item index="1-1-2">燃烧代理人萨沙</el-menu-item>
                                <el-menu-item index="1-1-3">燃烧代理人血袭</el-menu-item>
                                <el-menu-item index="1-1-4">燃烧代理人天使</el-menu-item>
                            </el-submenu>
                             <el-submenu index="1-2">
                                <template slot="title">混乱团队</template>
                                <el-menu-item index="1-2-1">混乱团队a族</el-menu-item>
                                <el-menu-item index="1-2-2">混乱团队c族</el-menu-item>
                                <el-menu-item index="1-2-3">混乱团队g族</el-menu-item>
                                <el-menu-item index="1-2-4">混乱团队m族</el-menu-item>
                            </el-submenu>
                            <el-submenu index="1-3">
                                <template slot="title">混乱基地</template>
                                <el-menu-item index="1-3-1">混乱基地g族塔罗斯</el-menu-item>
                            </el-submenu>
                            <el-submenu index="1-4">
                                <template slot="title">舰船配置</template>
                                <el-menu-item index="1-4-1">混乱团队代理人通刷涅加尔配置</el-menu-item>
                                <!-- 暂无视频 -->
                                <el-menu-item index="1-4-2">混乱基地g族塔罗斯恩尤配置</el-menu-item>
                                
                            </el-submenu>
                        </el-submenu>
                        <el-submenu index="2">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span>5级任务g族攻略(战列速刷和掠夺通刷)</span>
                            </template>
                            <el-submenu index="2-1">
                                <template slot="title">战列舰</template>
                                <el-menu-item index="2-1-1">斩龙行动</el-menu-item>
                                <el-menu-item index="2-1-2">灭龙行动</el-menu-item>
                                <el-menu-item index="2-1-3">自由间谍1</el-menu-item>
                                <el-menu-item index="2-1-4">净化的火焰</el-menu-item>
                                <el-menu-item index="2-1-5">天使的愤怒</el-menu-item>   
                            </el-submenu>
                            <el-submenu index="2-2">
                                <template slot="title">掠夺舰</template>
                                <el-menu-item index="2-2-1">解放矿工</el-menu-item>
                                <el-menu-item index="2-2-2">净化的火焰</el-menu-item>
                                <el-menu-item index="2-2-3">聚集力量</el-menu-item>
                                <el-menu-item index="2-2-4">开拓号</el-menu-item>
                                <el-menu-item index="2-2-5">灭龙行动</el-menu-item>
                                <el-menu-item index="2-2-6">清理门户</el-menu-item>   
                                <el-menu-item index="2-2-7">荣誉</el-menu-item>
                                <el-menu-item index="2-2-8">萨沙取物</el-menu-item>
                                <el-menu-item index="2-2-9">天使的愤怒</el-menu-item>
                                <el-menu-item index="2-2-10">袭击护航队a族</el-menu-item>
                                <el-menu-item index="2-2-11">袭击护航队c族</el-menu-item>
                                <el-menu-item index="2-2-12">越狱(2)</el-menu-item>
                                <el-menu-item index="2-2-13">斩龙行动</el-menu-item>
                                <el-menu-item index="2-2-14">重重一蛰(1)</el-menu-item>
                                <el-menu-item index="2-2-15">重重一蛰(2)</el-menu-item>
                                <el-menu-item index="2-2-16">繁殖设施</el-menu-item>
                            </el-submenu>
                        </el-submenu>
                    </el-menu>
                </el-col>
            </el-aside>
            <!-- 右边主体 -->
            <el-main>
                <h2>{{this.name + '&emsp;&emsp;高画质请点击切换画质,会跳转至b站观看高清画质视频'}}</h2>
                <iframe :src="this.url" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" style="height:1000px;width: 100%;"> </iframe>
                <!-- <el-button @click="task">vxcvx</el-button> -->
            </el-main>
        </el-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //存在store里面的state中
            task: this.$store.state.task,
            //赋值的任务数组
            taskArray:[],
            //实际赋值路径
            url:'//player.bilibili.com/player.html?aid=675318801&bvid=BV1WU4y1P7wC&cid=403480163&page=2',
            name:'燃烧代理人天蛇',
        }
    },
    methods: {
      chooseIndex(key){
        this.taskArray = this.task.filter( item => item.index === key);
        this.url = this.taskArray[0].url;
        this.name = this.taskArray[0].name;
      }
    
    },
    computed:{
    }
}
</script>

<style lang="less" scoped>
.task {
    height: 100% !important;
}

.el-menu {
    height: 100vh;
    width: 350px;
}
</style>