<template>
  <div>
    <el-row>
      <el-col :span="8"><el-button type="primary" @click="dmDialogVisible = true" size="medium">中转英翻译(注意该功能反应需要1秒，输入请输入完全如洛基级等)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="jhDialogVisible = true" >查询星系击毁数量(过去一小时)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="shicang">市场功能(页面植入)</el-button></el-col>
    </el-row>
    {{'&emsp;'}}
    <el-row>
      <el-col :span="8"> <el-button type="primary" @click="eveLp">查询evelp(页面植入)</el-button></el-col>
      <el-col :span="8"> <a href="https://zkillboard.com/" target="_blank"><el-button type="primary">查询eveKb(页面跳转)</el-button></a></el-col>
      <el-col :span="8"> <el-button type="primary" @click="evehole">查询eve虫洞(页面植入)</el-button></el-col>
    </el-row>
    {{'&emsp;'}}
    <el-row>
      <el-col :span="8"> <el-button type="primary" @click="evenavigaion">查询旗舰导航(页面植入)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="csfDialogVisible = true" >测试服指令</el-button></el-col>
      <el-col :span="8"><a href="https://mutaplasmid.space" target="_blank"><el-button type="primary">查询深渊装备价格(页面跳转)</el-button></a></el-col>
    </el-row>
    {{'&emsp;'}}
    <el-row>
      <el-col :span="8"><el-button type="primary" @click="appeal" >掉线损申诉教学(页面植入)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="chinese" >汉化工具(页面植入)</el-button></el-col>
      <el-col :span="8"> <el-button type="primary" @click="scanning" >扫描工具(页面植入)</el-button></el-col>
    </el-row>
    {{'&emsp;'}}
    <el-row>
      <el-col :span="8"><el-button type="primary" @click="evemarket" >eve市场有估价功能和合同功能(页面植入)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="eveindustry" >eve工厂有计算成本功能(页面植入)</el-button></el-col>
      <el-col :span="8"><el-button type="primary" @click="deadspace" >死亡空间(页面植入)</el-button></el-col>
    </el-row>
    {{'&emsp;'}}
    <el-row>
      <el-col :span="8"><a href="https://forums.eveonline.com/c/marketplace/character-bazaar/60" target="_blank"><el-button type="primary">eve欧服角色交易论坛(页面跳转)</el-button></a></el-col>
      <el-col :span="8"><el-button type="primary" @click="evewiki" >eve欧服维基首页(页面植入)</el-button></el-col>
      <el-col :span="8"><a href="http://frt.gitbook.io/eve/" target="_blank"><el-button type="primary">使用Steam，畅玩EVE教学(页面跳转)</el-button></a></el-col>
    </el-row>
   
   {{'&emsp;'}}
   
    <!-- 名字翻译 -->
    <el-dialog
      title="名字翻译"
      :visible.sync="dmDialogVisible"
      width="30%"
      @close="fyDialogClosed">
      <span>
        <el-form ref="translateFormRef" :model="translateForm" label-width="80px">
          <el-form-item label="名字(中)" prop="translateName">
            <el-input v-model="translateForm.translateName" clearable></el-input>
          </el-form-item>
          <el-form-item label="英文">
            <el-input v-model="translateForm.answerName">{{this.translateForm.answerName}}</el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dmDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="translate">翻译</el-button>
      </span>
    </el-dialog>
    <!-- 查询星系击毁数量过去一小时 -->
     <el-dialog
      title="查询星系击毁数量(过去一小时)"
      :visible.sync="jhDialogVisible"
      width="30%"
      @close="dyDialogClosed">
      <span>
        <el-form ref="destroyFormRef" :model="destroyForm" label-width="120px">
          <el-form-item label="查询的星系(中)" prop="starName" >
            <el-input v-model="destroyForm.starName" clearable></el-input>
          </el-form-item>
          <el-form-item label="npc击毁数量">
            <el-input v-model="destroyForm.npcNum">{{this.destroyForm.npcNum}}</el-input>
          </el-form-item>
           <el-form-item label="克隆舱击毁数量">
            <el-input v-model="destroyForm.podNum" >{{this.destroyForm.podNum}}</el-input>
          </el-form-item>
           <el-form-item label="船只击毁数量">
            <el-input v-model="destroyForm.shipNum">{{this.destroyForm.shipNum}}</el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="jhDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="queryDestroy">查询</el-button>
      </span>
    </el-dialog>
    <!-- 测试服指令 -->
    <el-dialog el-dialog
      title="测试服指令"
      :visible.sync=" csfDialogVisible"
      width="60%">
      <span>
        <img src="@/assets/img/csf.png" alt="">
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return{
      dmDialogVisible: false,
      // 翻译表单
      translateForm:{
        translateName:'',
        answerName:'',
        id:'',
      },
      //翻译得到的表单
      answerForm:{
        category:'',
        id:'',
        name:'',
      },
      //eve服务器得到的翻译数组
      answerArray:[],
      data:'',
      pd:{},
      // 查询星系击毁数量对话框
      jhDialogVisible: false,
      //星系击毁表单对话框
      destroyForm:{
        starName:'',
        npcNum:'',
        podNum:'',
        shipNum:''
      },
      //从eve数组中得到的击毁数组
      destoryArray:[],
      a:[],
      csfDialogVisible:false
    }
  },
  methods:{
    //翻译
    async translate(){
    const {data : res} =await this.$http.post("universe/ids/?datasource=tranquility&language=zh",[this.translateForm.translateName]);
    if (JSON.stringify(res)=="{}"){
      this.$message.error('输入名字不存在，请输入正确名字')
    }
    else {if(res.inventory_types != undefined){
            this.answerArray = res.inventory_types;
            await this.queryId();
          }
          else{
            this.answerArray = res.systems;
            await this.queryId();
              }
          // console.log(res);
        }
    },
    //获得id
    async queryId(){
      const {data : res} =await this.$http .post("universe/names/?datasource=tranquility",[this.answerArray[0].id])
      if(res.inventory_types != undefined){
        this.answerArray = res.inventory_types;
        this.translateForm.answerName = this.answerArray[0].name;
        this.translateForm.id = this.answerArray[0].id;
      }
      else{
      this.answerArray = res;
      this.translateForm.answerName = this.answerArray[0].name;
      this.translateForm.id = this.answerArray[0].id;
      }
      // console.log(this.translateForm.answerName );
    },
    //监听翻译对话框关闭
    fyDialogClosed (){
      this.$refs.translateFormRef.resetFields();
    },
    //查询星系击毁
    async queryDestroy(){
      this.translateForm.translateName = this.destroyForm.starName
      await this.translate();
      const {data : res} =await this.$http.get("/universe/system_kills/");
      console.log(res);
      this.destoryArray = res.filter(item => item.system_id === this.translateForm.id);
      this.destroyForm.npcNum = this.destoryArray[0].npc_kills;
      this.destroyForm.podNum = this.destoryArray[0].pod_kills;
      this.destroyForm.shipNum = this.destoryArray[0].ship_kills;
      this.translateForm.answerName = '';
      this.translateForm.translateName = '';
    },
    //监听查询星系击毁关闭对话框
    dyDialogClosed(){
       this.$refs.destroyFormRef.resetFields();
    },
    //市场功能
    shicang(){
       this.$router.push('/market')
    },
    //查询evelp
     eveLp(){
       this.$router.push('/eveLp')
    },
    //洞
     evehole(){
       this.$router.push('/eveHole')
    },
    //旗舰导航
    evenavigaion(){
      this.$router.push('/navigation')
    },
    // 掉线损申诉教学
    appeal(){
      this.$router.push('/appeal')
    },
    //汉化工具
    chinese(){
      this.$router.push('/chinese')
    },
    scanning(){
      this.$router.push('/scanning')
    },
    // eve市场有估价功能和合同
    evemarket(){
      this.$router.push('/evemarket')
    },
    // eve工厂有计算成本功能
    eveindustry(){
      this.$router.push('/eveindustry')
    },
    //死亡空间
    deadspace(){
      this.$router.push('/deadspace')
    },
    //evewiki
    evewiki(){
      this.$router.push('/evewiki')
    }

  }

}
</script>

<style lang="less" scoped>
</style>